@import "styles/mixins";
@import "styles/variables";

.reports-list {
    @include size(100%, fit-content);
    @include flexStyles(flex, column, flex-start, flex-start);
    gap: 10px;

    & div {
      padding: 10px;
      border-radius: 10px;
      background-color: $post-bg-color;
    }

    .activee-reason {
      background: $button-color;
      @include textStyles($fz-16, $medium, 20px, $white, start);
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    }
  }