@import "styles/mixins";
@import "styles/variables";

.patients-filtration__section {
  margin-bottom: 50px;
  width: 100%;

  .filter-icon {
    @include size(20px, 20px);
    padding-left: 3px;
  }
  
}

.patient-filtration__form {

  & > div {
    flex: 1 1 0;
    min-width: 300px;
  }

  .apply-button{
    flex: 1 1 0;
    display: flex;
    gap: 20px;
  }

  .select-container, .checkbox-container{
    width: 100%;
    @include flexStyles(flex, row, space-between, flex-start);
    gap: 20px;
    margin-bottom: 20px;
  }


}
