@import "styles/mixins";
@import "styles/variables";

.searchContainer {
  @include flexStyles(flex, row, center, center);
  background-color: $white;
  border: 1px solid $light-grey;
  border-radius: 8px;
  padding: 8px 12px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:focus-within {
    border-color: $button-color;
    box-shadow: 0 0 5px $marine-blue;
  }
}

.searchIcon {
  @include size(20px, 20px);
  color: $dark-grey;
  margin-right: 8px;
  font-size: 1.2rem;
}

.searchInput {
  border: none;
  outline: none;
  flex: 1;
  font-size: 1rem;
  color: $dark-blue;
  background-color: transparent;

  &::placeholder {
    color: $dark-grey;
  }

  &:focus::placeholder {
    color: $light-grey;
  }
}
