
@mixin flexStyles ($flex, $flex-direction, $justify-content, $align-items) {
  display: $flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin textStyles ($font-size, $font-weight, $line-height, $color, $text-align) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  text-align: $text-align;
}

@mixin imageSize($size, $pozition) {
    object-fit: $size;
    object-position: $pozition;
  }
  
  @mixin backgroundSize($size, $pozition, $repeat) { 
    background-size: $size;
    background-repeat: $repeat;
    background-position: $pozition;
  }
  
  @mixin size($width, $height) {
    width: $width;
    height: $height;
  }
  @mixin for_laptop {
    @media (max-width: 1024px) { @content; }
  }
  @mixin for_tablet {
    @media (max-width: 768px) { @content; }
  }
  @mixin for_mobile {
    @media (max-width: 480px) { @content; }
  }
  @mixin for_mobileMedium {
    @media (max-width: 375px) { @content; }
  }
  @mixin for_mobileMini {
    @media (max-width: 320px) { @content; }
  }
  
  
  