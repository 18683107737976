@import "styles/mixins";
@import "styles/variables";

.slider-image{
    @include size(250px, 200px);
    object-fit: cover;
}

.images-count{
    @include textStyles($fz-20, $medium, 20px, $button-color, start);
}