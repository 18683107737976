@import "styles/mixins";
@import "styles/variables";

.error-container{
    @include flexStyles(flex, column, center, center);
    gap: 30px;

    .error-img{
        width: 100%;
    }
}