@import "styles/mixins";
@import "styles/variables";

.checkbox-container{
    width: 100%;
    @include flexStyles(flex, column, flex-start, flex-start);
    gap: 8px;

    & .label{
        @include textStyles($fz-16, $medium, 20px, $dark-blue, start);
    }

    & .input-error {
        padding: 5px 0;
        color: $error-red;
      }
}