@import "styles/mixins";
@import "styles/variables";

.doctor-filtration__form{
    @include flexStyles(flex, row, space-between, flex-end);
    gap: 30px;
    margin-bottom: 50px;

    & > div {
        flex: 1 1 0; 
        max-width: 250px; 
    }
}

.reset-filter-icon{
    @include size(20px, 20px);

    &:hover{
        cursor: pointer;
    }
}