@import "styles/mixins";
@import "styles/variables";

.license-item__container {
  @include flexStyles(flex, row, center, center);
  gap: 20px;
  flex-wrap: wrap;

  .license-item {
    @include size(200px, fit-content);
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    @include flexStyles(flex, column, space-around, center);
    gap: 10px;

    .uploadFileImg{
      @include size (50px, 50px);
      object-fit: cover;
    }

    &:hover {
      cursor: pointer;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
        rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      transition: 0.5s;
    }

    .license-actions{
      @include flexStyles(flex, row, center, space-between);
      gap: 10px;

      .delete-licence {
        @include textStyles($fz-16, $medium, 20px, $error-red, start);
  
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .download-licence {
        @include textStyles($fz-16, $medium, 20px, $button-color, start);
  
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }    
  }
}

.new-license-form{
  @include flexStyles(flex, column, center, center);
  gap: 20px;
}