@import "styles/mixins";
@import "styles/variables";

.user-avatar {
  @include size(40px, 40px);
  object-fit: cover;
  border-radius: 100%;
}

.user-avatar__container{
    @include flexStyles(flex, row, flex-start, center);

    span {
        margin-right: 20px;
    }
}
