@import "styles/mixins";
@import "styles/variables";

.profile-img__container {
  @include size(150px, 150px);

  .profile-img {
    @include size(100%, 100%);
    object-fit: cover;
    border-radius: 8px;
  }

  .profile-img__loader{
    @include size(150px, 150px);
    @include flexStyles(flex, row, center, center)
  }
}

.upload-img__container {
  position: relative;

  & .camera-icon {
    cursor: pointer;
    object-fit: cover;
    @include size(30px, 30px);
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
