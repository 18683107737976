@import "styles/mixins";
@import "styles/variables";

.login-page {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: #f2f2f2;
  @include flexStyles(flex, row, center, center);

  .form-container {
    width: 500px;
    height: fit-content;
    padding: 40px 20px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;

    & .title {
      @include textStyles($fz-24, $bold, 24px, $button-color, center);
      margin-bottom: 30px;
    }

    & .form {
      width: 100%;
      @include flexStyles(flex, column, center, center);
      gap: 15px;
    }
  }
}
