@import "styles/mixins";
@import "styles/variables";

.select-container {
  width: 100%;

  & .label {
    display: block;
    @include textStyles($fz-16, $medium, 20px, $dark-blue, start);
    margin-bottom: 7px;
  }

  & .select-error {
    padding: 5px 0;
    color: $error-red;
  }
}
