@import "styles/mixins";
@import "styles/variables";

.container {
  width: 100%;
  @include flexStyles(flex, row, flex-start, flex-start);
  gap: 50px;
  margin-bottom: 50px;
}

.doctors-additional-info__form {
  width: 100%;

  .row, .doctor-app-goals-row {
    @include flexStyles(flex, row, flex-start, flex-start);
    width: 100%;
    max-width: 100%;
    gap: 15px;
    margin-bottom: 15px;

    .label {
      display: block;
      @include textStyles($fz-16, $medium, 20px, $dark-blue, start);
      margin-bottom: 7px;
    }
  }

  .doctor-license-row, .doctor-certifications-row{
    @include flexStyles(flex, column, flex-start, flex-start);
    gap: 15px;
    margin-bottom: 15px;

    .label {
      display: block;
      @include textStyles($fz-16, $medium, 20px, $dark-blue, start);
      margin-bottom: 7px;
    }
  }
}
