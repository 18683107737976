@import 'styles/mixins';
@import 'styles/variables';

.main-page {
  display: flex;
  min-height: 100vh;
  width: 100%;

  &__navbar {
    min-width: 20%;
    width: 20%;
    height: auto;
    @include flexStyles(flex, column, flex-start, center);
    gap: 15px;
    background-color: $dark-blue;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    padding: 200px 20px 20px;

    & > div {
      margin-bottom: 10px;
    }

    .navbar-item {
      color: $white;
      font-size: 18px;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        border-bottom: 2px solid $white;
        transition: all 0.3s ease;
      }

      &:active {
        background-color: $dark-grey;
      }
    }

    .logout-button {
      background-color: $dark-blue;
      color: $white;
      font-size: 16px;
      padding: 10px 20px;
      border: 2px solid $button-color;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease,
        box-shadow 0.3s ease, border-color 0.3s ease;

      &:hover {
        border-color: $marine-blue;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
      }

      &:active {
        background-color: lighten($dark-blue, 10%);
        border-color: lighten($marine-blue, 10%);
        box-shadow: none;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(80, 91, 241, 0.5);
      }
    }
  }
}
