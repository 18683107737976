@import "styles/mixins";
@import "styles/variables";

.container {
  width: 100%;
  @include flexStyles(flex, row, flex-start, flex-start);
  gap: 50px;
  margin-bottom: 50px;

  .user-edit-form {
    width: 100%;
    @include flexStyles(flex, row, flex-start, flex-start);
    gap: 50px;
  }

  .profile-img__container {
    @include size(150px, 150px);
    border-radius: 8px;

    .profile-img {
      @include size(100%, 100%);
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .personal-info {
    width: 50%;

    .row {
      @include flexStyles(flex, row, flex-start, flex-start);
      width: 100%;
      gap: 15px;
      margin-bottom: 15px;
      
      .label {
        display: block;
        @include textStyles($fz-16, $medium, 20px, $dark-blue, start);
        margin-bottom: 7px;
      }
    }
  }
}
