@import "styles/mixins";
@import "styles/variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  a {
    text-decoration: none;
    color: unset;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-heading {
  margin-bottom: 30px;
}

.MuiPaper-root {
  min-width: 400px;
  padding: 20px;
  border-radius: 12px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: $white;
  color: $dark-blue;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;

  thead {
    background-color: $button-color;
    color: $white;

    th {
      padding: 12px;
      font-weight: bold;
      text-align: left;

      &:last-child {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      transition: background-color 0.2s;

      &:nth-child(even) {
        background-color: lighten($marine-blue, 30%);
      }

      &:hover {
        cursor: pointer;
      }
    }

    td {
      padding: 12px;
      color: $dark-grey;

      &:last-child {
        text-align: center;
      }
    }
  }
}

.delete-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  background-color: $error-red !important;
  color: $white;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.05);
    background-color: darken($error-red, 10%);
    transition: transform 0.5s ease;
  }
}

.restore-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  background-color: $success-green !important;
  color: $white;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.05);
    background-color: darken($success-green, 10%);
    transition: transform 0.5s ease;
  }
}

.edit-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  background-color: $dark-blue;
  color: $white;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.05);
    background-color: darken($dark-blue, 10%);
    transition: transform 0.5s ease;
  }
}

.outlet-page {
  padding: 50px;
  width: 80%;
  flex: 1;
}

.react-tel-input .form-control {
  @include size(100% !important, fit-content !important);
  border-radius: 8px !important;
  border: 2px solid $light-grey !important;
  padding: 6px 6px 6px 50px !important;
  @include flexStyles(flex, row, center, center);

  &:focus {
    border-color: $button-color !important;
    box-shadow: 0 0 5px $marine-blue !important;
    outline: none !important;
  }
}

.flag-dropdown {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  @include size(37px, 37px);
  top: 2px !important;
  bottom: 2px !important;
  left: 2px !important;

  &:focus {
    border-color: $button-color !important;
    box-shadow: 0 0 5px $marine-blue !important;
    outline: none !important;
  }
}

.selected-flag {
  @include size(100%, 100%);
  &:hover {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.loader-page {
  @include size(100%, 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select .MuiInputBase-root,
.multi-custom-select .MuiInputBase-root {
  width: 100% !important;
  max-width: 100% !important;

  & .MuiSelect-select {
    padding: 10px !important;
  }
}

.MuiFormControl-root {
  width: 100% !important;

  .MuiInputBase-root {
    padding: 4px 10px !important;
    border-radius: 8px !important;

    input {
      padding: 0 !important;
    }
  }
}

textarea {
  min-height: 250px !important;
}

.otp-input .MuiInputBase-root {
  padding: 20px 10px !important;
  font-size: 20px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus-within {
    border-color: $button-color !important;
    background-color: #f5f5f5 !important;
    outline: none !important;
    box-shadow: 0 0 5px $marine-blue !important;
  }
}

.otp-input .Mui-focused {
  outline: none !important;
  border-color: $button-color !important;
  box-shadow: 0 0 5px $marine-blue !important;
}

.custom_radio-btn {
  @include flexStyles(
    flex !important,
    row !important,
    flex-start !important,
    flex-start !important
  );
}

.MuiButtonBase-root {
  color: $button-color !important;
}

.MuiAccordionSummary-content {
  @include textStyles($fz-20, $medium, 20px, $button-color, start);
}

.slick-slider {
  @include size(250px !important, 200px !important);
}

.slick-next:before,
.slick-prev:before {
  color: $dark-blue !important;
}

.slick-dots {
  display: none !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// @media screen and (max-width: 1440px) {
//   .custom-select .MuiInputBase-root,
//   .multi-custom-select .MuiInputBase-root {
//     max-width: 150px !important;
//   }
// }
