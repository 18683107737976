@import "styles/mixins";
@import "styles/variables";

.actions {

  @include flexStyles(flex, row, center, center);
  gap: 15px;
  padding-top: 20px;

  .reject-btn {
    background-color: $error-red;
  }
}
