@import "styles/mixins";
@import "styles/variables";

.otp-error{
    color: $error-red;
}

.resend-btn{
    @include textStyles($fz-18, $bold, 24px, $button-color, center);
    padding-left: 5px;

    &:hover{
        cursor: pointer;
    }
}
