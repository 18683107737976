@import "styles/mixins";
@import "styles/variables";

.report-container {
  @include size(100%, fit-content);
  border-radius: 8px;
  padding: 12px;
  @include flexStyles(flex, row, flex-start, flex-start);
  gap: 100px;
  margin-bottom: 30px;

  & .report-info__container {
    @include size(100%, fit-content);
    @include flexStyles(flex, column, flex-start, flex-start);
    gap: 20px;

    .report-info__item {
      background-color: $post-bg-color;
      padding: 30px;
      width: 100%;
      border-radius: 10px;

      & .label {
        @include textStyles($fz-20, $medium, 20px, $dark-grey, start);
        margin-bottom: 10px;
      }

      & .content {
        @include textStyles($fz-16, $medium, 20px, black, start);
      }
    }
  }
}

.post {
  @include size(30%, fit-content);
  padding: 10px;
  border-radius: 10px;
  background-color: $post-bg-color;
  @include flexStyles(flex, column, flex-start, center);
  gap: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  .post-img{
    @include size(250px, 200px);
    object-fit: contain;
  }

  .text-content{
    @include textStyles($fz-16, $medium, 20px, $dark-grey, start);
  }
}
