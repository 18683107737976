// colors
$button-color:#053D57;
$marine-blue: rgba(80, 91, 241, 1);
$white: rgba(255, 255, 255, 1);
$black: rgba(255, 255, 255, 0);
$dark-grey: rgba(131, 131, 131, 1);
$light-grey: rgba(162, 165, 177, 1);
$error-red: rgba(234, 52, 52, 1);
$success-green: rgba(68, 180, 97, 1);
$dark-blue: rgba(28, 27, 75, 1);
$post-bg-color: #f5f7fa;

// font sizes
$fz-10:10px;
$fz-12:12px;
$fz-13:13px; 
$fz-16:16px;
$fz-14:14px;
$fz-18:18px;
$fz-19:19px;
$fz-20:20px;
$fz-22:22px;
$fz-24:24px;
$fz-28:28px;
$fz-30:30px;
$fz-34:34px;
$fz-40:40px;
$fz-42:42px;

// font weights 
$regular:400;
$medium:500;
$semiBold:600;
$bold:700;

